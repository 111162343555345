import React from 'react';
import Countdown from './Countdown';
import './RSVP.css';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Fade } from "react-awesome-reveal";
import Modal from 'react-bootstrap/Modal';

const RSVP = ({ rsvpRef }) => {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <iframe src="https://fm.addxt.com/form/?vf=1FAIpQLSfRDmW7oUVmgg1bnUmqbNF2goZ2tnLaZJsN1C4HGwNRFMzk0A" width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div className='rsvp' ref={rsvpRef}>
            <Fade triggerOnce duration={2500}>
                <div>
                    <img src="/img/monogram_transparent.png" />
                    <div className='content-wrapper'>
                        <div className='content'>
                            <h3><div dangerouslySetInnerHTML={{ __html: t('date') }} /></h3>
                            <Countdown />
                            <Button variant="outline-dark" onClick={() => setModalShow(true)}>{t('home')}</Button>
                        </div>
                    </div>
                </div>
            </Fade>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
}

export default RSVP;