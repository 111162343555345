import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "menu": "Menu",
            "we": "We,",
            "stella&kostadin": "Stella & Kostadin",
            "message": "would be delighted to share our joy with you and celebrate the beginning of our family life!",
            "date": "Friday</br>September 6, 2024",
            "location": "Sofia, Bulgaria",
            "welcome": "Home",
            "home": "RSVP",
            "story": "Our Story",
            "schedule": "Schedule",
            "qna": "Q&A",
            "days": "days",
            "hours": "hours",
            "minutes": "min",
            "seconds": "seconds",
            'schedule_reception': '"Welcome" drink',
            'schedule_ceremony': 'Wedding ceremony',
            'schedule_gifts': 'Wedding gifts & cocktail',
            'schedule_restaurant': 'Restaurant',
            'schedule_party': 'Reception of the newlyweds',
            'wedding_cake': 'Wedding cake',
            'qna_desc': 'For all our friends and family who have lots of questions',
            "q1": "Wedding venue",
            "q2": "RSVP deadline",
            "q3": "Parking",
            "q4": "Flowers",
            "q5": "Gifts",
            "q6": "Plus one (+1)",
            "q7": "Additional inquiries",
            "a1": "St. Sofia Golf & Spa, Ravno pole",
            "a2": "May 31, 2024",
            "a3": "Parking spots are guaranteed for all our guests.",
            "a4": "Please, do not bring flowers - replace them with an interesting book instead.",
            "a5": "Instead of gifts, support our first family home.",
            "a6": "You can bring a plus one, only if discussed with us beforehand.",
            "a7": 'Contact Stella (<a href="tel:+359882297079">0882297079</a>) or Kostadin (<a href="tel:+359878267171">0878267171</a>).',
        }
    },
    bg: {
        translation: {
            "menu": "Меню",
            "we": "Ние,",
            "stella&kostadin": "Стела и Костадин",
            "message": "ще се радваме да споделим щастието си с вас и да отпразнуваме началото на нашия семеен живот!",
            "date": "Петък</br>6&nbsp;&nbsp;Септември, 2024",
            "location": "София, България",
            "welcome": "Начало",
            "home": "Потвърди",
            "story": "История",
            "schedule": "Програма",
            "qna": "Q&A",
            "days": "дни",
            "hours": "часа",
            "minutes": "мин",
            "seconds": "секунди",
            'schedule_reception': 'Напитка за “Добре дошли“',
            'schedule_ceremony': 'Сватбена церемония',
            'schedule_gifts': 'Поздравления и коктейл',
            'schedule_restaurant': 'Официална вечерия',
            'schedule_party': 'Посрещане на младоженци',
            'wedding_cake': 'Сватбена торта',
            'qna_desc': 'За всички наши приятели и гости, които имат куп въпроси',
            "q1": "Място на сватбеното събитие",
            "q2": "Краен срок за потвърждение",
            "q3": "Паркинг",
            "q4": "Цветя",
            "q5": "Подаръци",
            "q6": "Придружител (+1)",
            "q7": "Допълнителни въпроси",
            "a1": "Голф клуб „Света София“, Равно поле",
            "a2": "31 Май 2024",
            "a3": "Паркингът е гарантиран за всички наши гости.",
            "a4": "Молим, цветя не носете, а с интересна книга ги заменете.",
            "a5": "За подаръците не мислете, нашия първи семеен дом подкрепете.",
            "a6": "Може да доведете +1, само ако е потвърдено с нас предварително.",
            "a7": 'Свържете се със Стела (<a href="tel:+359882297079">0882297079</a>) или Костадин (<a href="tel:+359878267171">0878267171</a>).',
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "bg", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;