import 'bootstrap/dist/css/bootstrap.min.css';
import './QnA.css';
import { useState, useEffect } from 'react';
import './Language.css';
import { useTranslation } from 'react-i18next';

const Language = () => {
    const { t, i18n } = useTranslation();

    const languageOptions = {
        "bg": {
            value: "bg",
            label: "BG",
            image: "/img/flag-bg.png"
        },
        "en": {
            value: "en",
            label: "EN",
            image: "/img/flag-uk.png"
        }
    }

    // Set the initial state based on the current i18n language
    const [language, setLanguage] = useState(languageOptions[i18n.language] || languageOptions.bg);

    useEffect(() => {
        // Update the selected state if the i18n language changes
        setLanguage(languageOptions[i18n.language] || languageOptions.bg);
    }, [i18n.language]);

    function switchLanguage() {
        let newLanguage = language.value == "bg" ? "en" : "bg";

        i18n.changeLanguage(newLanguage).then(() => {
            localStorage.setItem('selectedLanguage', newLanguage);
        });
    }

    return (
        <div className='language' onClick={() => switchLanguage()}>
            <img src={language.image} alt={language.label} height='32' />
            <span>{language.label}</span>
        </div>
    );
};

export default Language;