import React from 'react';
import './Schedule.css';
import { useTranslation } from 'react-i18next';
import Timeline from './Timeline';

const Schedule = ({ scheduleRef }) => {
    const { t, i18n } = useTranslation();

    const events = [
        { time: '16:30', icon: 'champagne', key: 'schedule_reception' },
        { time: '17:00', icon: 'diamond-ring', key: 'schedule_ceremony' },
        { time: '17:15', icon: 'gift', key: 'schedule_gifts' },
        { time: '19:00', icon: 'restaurant', key: 'schedule_restaurant' },
        { time: '19:40', icon: 'wedding', key: 'schedule_party' },
        { time: '23:00', icon: 'wedding-cake', key: 'wedding_cake' }
    ];

    return (
        <div className="section schedule" ref={scheduleRef}>
            <h2>{t('schedule')}</h2>
            <hr />
            <Timeline items={events} />
        </div>
    );
};


export default Schedule;