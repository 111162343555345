import 'bootstrap/dist/css/bootstrap.min.css';
import './Title.css';
import { useTranslation } from 'react-i18next';

const Title = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className='title'>
            <span>{t('we')}</span>
            <h1>{t('stella&kostadin')}</h1>
            <p>{t('message')}</p>
        </div>
    );
};

export default Title;