import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Menu = ({ homeRef, rsvpRef, storyRef, scheduleRef, qnaRef }) => {
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        handleClose();
    };

    return (
        <>
            <div className='menu'>
                <Button className='menu-btn' variant="light" onClick={handleShow}>
                    <FontAwesomeIcon icon={faBars} size='lg' className='menu-icon' />
                </Button>
                <span>{t('menu')}</span>
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <img className='menu-flowers' src="/img/flowers.png" />
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><h1>{t('stella&kostadin')}</h1></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        <Nav.Link className='home-link' onClick={() => scrollToSection(homeRef)}>{t('welcome')}</Nav.Link>
                        <hr className='home-link' />
                        <Nav.Link onClick={() => scrollToSection(rsvpRef)}>{t('home')}</Nav.Link>
                        <hr />
                        {/* <Nav.Link onClick={() => scrollToSection(storyRef)}>{t('story')}</Nav.Link>
                        <hr /> */}
                        <Nav.Link onClick={() => scrollToSection(scheduleRef)}>{t('schedule')}</Nav.Link>
                        <hr />
                        <Nav.Link onClick={() => scrollToSection(qnaRef)}>{t('qna')}</Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Menu;
