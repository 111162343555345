import 'bootstrap/dist/css/bootstrap.min.css';
import './QnA.css';
import { useTranslation } from 'react-i18next';
import { Fade } from "react-awesome-reveal";

const QnA = ({ qnaRef }) => {
    const { t, i18n } = useTranslation();

    const qna_i18n = [
        { q: 'q1', a: 'a1' },
        // { q: 'q2', a: 'a2' },
        { q: 'q3', a: 'a3' },
        { q: 'q4', a: 'a4' },
        { q: 'q5', a: 'a5' },
        { q: 'q6', a: 'a6' },
        { q: 'q7', a: 'a7' }
    ];

    const showMaps = () => {
        return (
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.539902748731!2d23.5376205!3d42.671104500000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa62cd035f1277%3A0x2c352dd1c64ba9!2z0KHQsi4g0KHQvtGE0LjRjyDQs9C-0LvRhCDQutC70YPQsSDQuCDRgdC_0LA!5e0!3m2!1sbg!2sbg!4v1705617924600!5m2!1sbg!2sbg"
                width="100%"
                height="300"
                style={{ "border": 0 }}
                allowFullScreen=""
                loading="async"
                referrerPolicy="no-referrer-when-downgrade"
            />
        );
    };

    return (
        <div className="section qna" ref={qnaRef}>
            <h2>{t('qna')}</h2>
            <hr />
            <span>{t('qna_desc')}</span>

            {qna_i18n.map((item, index) => (
                <Fade triggerOnce duration={2000} direction='left' className='qna-item' key={index}>
                    <div>
                        <h4># {t(item.q)}</h4>
                        <p dangerouslySetInnerHTML={{__html: t(item.a)}}></p>
                        {item.q == 'q1' ? showMaps() : null}
                    </div>
                </Fade>
            ))}
        </div>
    );
};

export default QnA;