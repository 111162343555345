import React, { useState, useEffect, useRef } from 'react';
import { Fade } from "react-awesome-reveal";
import Schedule from './Schedule';
import RSVP from './RSVP';
import QnA from './QnA';
import './Home.css';
import Menu from './Menu';
import Title from './Title';
import Language from './Language';
import Story from './Story';

const Home = () => {
    const [navOpacity, setNavOpacity] = useState(0);

    const homeRef = useRef(null);
    const rsvpRef = useRef(null);
    const storyRef = useRef(null);
    const scheduleRef = useRef(null);
    const qnaRef = useRef(null);

    useEffect(() => {
        const headerHeight = 100;
        const scrollThreshold = window.innerHeight; // 100vh
        const offset = headerHeight / 2;

        const didScrollPage = e => {
            const scrollY = window.scrollY;
            let calc = (scrollY - offset) / scrollThreshold;

            if (calc > 1) {
                calc = 1;
            } else if (calc < 0) {
                calc = 0;
            }

            setNavOpacity(calc);
        };

        window.addEventListener("scroll", didScrollPage);

        return () => {
            window.removeEventListener("keydown", didScrollPage);
        };
    }, []);

    return (
        <div className='outer-wrapper'>
            <div
                className='sticky-top'
                style={{ backgroundColor: `rgba(250, 212, 192, ${navOpacity})` }}
            >
                <Menu homeRef={homeRef} rsvpRef={rsvpRef} storyRef={storyRef} scheduleRef={scheduleRef} qnaRef={qnaRef} />
                <Language />
            </div>
            <div className="wrapper" ref={homeRef}>
                <div className='left' style={{ backgroundImage: '/img/title-img-1.jpg' }}>
                    <Fade triggerOnce duration={3000}><Title /></Fade>
                </div>
                <div className='right'>
                    <RSVP rsvpRef={rsvpRef} />
                    {/* <Story storyRef={storyRef} /> */}
                    <Schedule scheduleRef={scheduleRef} />
                    <QnA qnaRef={qnaRef} />
                </div>
            </div>
        </div >
    );
}

export default Home;