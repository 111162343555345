import React from 'react';
import { useTranslation } from 'react-i18next';
import './Timeline.css';
import { Fade } from "react-awesome-reveal";

const TimelineItem = ({ data, isLast }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="timeline-item">
            {!isLast ? <div className="timeline-line"></div> : null}
            <div className="timeline-dot"></div>

            <div className="time">{data.time}</div>
            <div className="description">
                <img src={'img/' + data.icon + '.png'} width={32} />
                &nbsp;&nbsp;
                {t(data.key)}
            </div>
        </div>
    );
};

const Timeline = ({ items }) => {
    return (
        <div className="timeline">
            {items.map((item, index) => (
                <Fade triggerOnce duration={3000} key={index}>
                    <TimelineItem key={index} data={item} isLast={items.length - 1 == index} />
                </Fade>
            ))}
        </div>
    );
};

export default Timeline;